<template>
  <div class="login_wrapper">
    <ch-icon name="login-logo" class="login-logo"></ch-icon>
    <img class="login_bg" src="@/assets/img/login_bg.png" alt="">
    <main class="flex_con login-box-wrapper">
      <div class="frame flex_con_column">
        <p class="title">欢迎登录系统</p>
        <login :type.sync="type" v-if="type === 1"/>
        <change-password :type.sync="type" v-if="type === 2"></change-password>
        <div class="treaty">
          登录即同意
          <router-link to="userAgreement" target="_blank" style="color: #6495ff">
            《用户服务协议》
          </router-link>
        </div>
      </div>
    </main>
    <div class="footer"><adminFooter/></div>
  </div>
</template>

<script>
import login from "@/views/login/login";
import ChangePassword from "@/views/login/changePassword";
import adminFooter from "@/views/admin/footer/adminFooter";
export default {
  components: { login,ChangePassword,adminFooter },
  data() {
    return {
      type: 1, // 登录1 忘记密码2
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.login_wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  .login-logo {
    position: absolute;
    z-index: 99;
    left: 70px;
    top: 60px;
    display: flex;
    align-items: center;
    width: 440px;
    height: 68px;
  }
  .login_bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
  // 用户协议
  .treaty {
    padding-top: 8px;
    font-size: 12px;
  }

  >.login-box-wrapper {
    display: flex;
    flex: 1;

    >.frame {
      position: absolute;
      right: 8vw;
      padding: 2vh 6vw 5vh;

      .CH_icon {
        width: 8vh;
        height: 10vh;
      }
      >.title {
        margin: 80px auto 30px;
        font-size: 28px;
        color: #333333;
        font-weight: 600;
      }
      align-items: center;    /*项目水平居中 */
      border-radius: 8px;
      flex-direction: column;
      background-color: rgba(255,255,255, 1);
      border: 0.5px solid rgba(255,255,255,0.1);
      backdrop-filter: blur(6px);/**毛玻璃 */
    }
  }

  .footer{
    color: #FFFFFF;
    margin: 10px auto ;
    display: flex;
  }
}

</style>


